<template>
    <div class="maxBox">
        <div class="pageBox" v-show="!relicFlag">
            <div class="topBox">
                <div class="TB-left"> 
                    <div class="titleBox titleCss">
                        <div>
                            <img src="../../assets/siteImg/title.png">
                            {{ museumName }}
                        </div>
                        <div class="floorBox">
                            <el-select v-model="room" @change="roomChange" placeholder="请选择">
                                <el-option
                                v-for="item in rooms"
                                :key="item.envId"
                                :label="item.envName"
                                :value="item.envId"
                                />
                            </el-select>
                        </div>
                    </div>
                    <div class="titleMsgBox">
                        <mapMode ref="mapMode" @mapEvent="mapEvent"/>
                    </div>
                </div>
                <div class="TB-right"> 
                    <div class="titleBox">
                        <img src="../../assets/siteImg/title.png">
                        文物风险占比 | 第一展览厅 - 展柜1
                    </div>
                    <div class="titleMsgBox">
                        <div class="tips">
                            <el-date-picker
                            v-model="TimeRang"
                            :clearable="false"
                            @change="ChangeTimeRang"
                            type="daterange"
                            range-separator="-"
                            value-format="YYYY-MM-DD"
                            format="YYYY-MM-DD"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            />
                        </div>
                        <div class="chartBox">
                            <div class="chartItem">
                                <lineChart :series="warningSeries" :yAxis="warningyAxis" />
                            </div>
                            <div class="chartItem">
                                <el-table :data="relicData" border style="width: 100%">
                                    <el-table-column prop="relicName" label="文物名称" />
                                    <el-table-column prop="relicTexture" label="材质" :width="70"/>
                                    <el-table-column prop="relicLevel" label="等级" :width="90"/>
                                    <el-table-column label="操作" :width="70">
                                        <template #default="scope">
                                            <el-button
                                            link
                                            type="primary"
                                            size="small"
                                            @click.prevent="look(scope.row)"
                                            >
                                            详情
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <el-pagination
                                    class="pagination"
                                    small
                                    layout="total, prev, pager, next"
                                    :total="total"
                                    :page-size="query.size"
                                    :current-page="query.page"
                                    @current-change="CurrentChange"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="centerBox">
                <div class="titleBox titleCss">
                    <div>
                        <img src="../../assets/siteImg/title.png">
                        环境监测指标 | 第一展览厅 - 展柜1
                    </div>
                    <div>
                        <el-date-picker
                        v-model="TimeRang1"
                        @change="ChangeTimeRang1"
                        type="datetimerange"
                        range-separator="-"
                        format="YYYY-MM-DD HH:mm:ss"
                        value-format="YYYY-MM-DD HH:mm:ss"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        />
                    </div>
                </div>
                <div class="titleMsgBox CB_main">
                    <div class="CB_item">
                        <lineChart :series="WSDline" :yAxis="WSDyAxis" />
                    </div>
                    <div class="CB_item">
                        <div class="CB_itemChart CB_itemTop">
                            <div class="CB_itemTop_item">
                                <lineChart :series="GZline" :yAxis="GZyAxis" />
                            </div>
                            <div class="CB_itemTop_item">
                                <lineChart :series="ZWline" :yAxis="ZWyAxis" />
                            </div>
                        </div>
                        <div class="CB_itemChart">
                            <lineChart :series="VOCline" :yAxis="VOCyAxis" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottomBox">
                <div class="BT-left">
                    <div class="titleBox titleCss">
                        <div>
                            <img src="../../assets/siteImg/title.png">
                            环境监测指标 | 第一展览厅 - 展柜1
                        </div>
                        <div class="operCss">
                            <el-radio-group v-model="senerType">
                                <el-radio-button v-for="(item,index) in senerTypeList" :key="index" :label="item.id">{{ item.name }}</el-radio-button>
                            </el-radio-group>
                            <el-date-picker
                                v-model="TimeRang2"
                                @change="ChangeTimeRang2"
                                type="daterange"
                                range-separator="-"
                                value-format="YYYY-MM-DD"
                                format="YYYY-MM-DD"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                            />
                        </div>
                    </div>
                    <div class="titleMsgBox">
                        <lineChart :series="dataline" :yAxis="datayAxis" />
                    </div>
                </div>
                <div class="BT-right">
                    <div class="titleBox titleCss">
                        <div>
                            <img src="../../assets/siteImg/title.png">
                            环境监测指标超限占比 | 第一展览厅 - 展柜1
                        </div>
                        <div>
                            <el-select v-model="limitTime" class="selectCss" placeholder="请选择">
                                <el-option
                                v-for="item in Times"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                />
                            </el-select> 
                        </div>
                    </div>
                    <div class="titleMsgBox">
                        <barChart/>    
                    </div>
                </div>
            </div>
        </div>
      
        <relicBox v-show="relicFlag" :relicMsg="relicMsg" @backPage="backPage"/>

    </div>
</template>
<script>
import mapMode from '@/components/leaflet/index.vue'
import pieChart from '../siteHome/modules/piechart.vue'
import lineChart from '@/components/charts/lineChart.vue'
import barChart from '@/components/charts/barChart.vue'
import relicBox from '../siteRelic/index.vue'
import { site_envList, site_envMap, site_envData, site_listDeviceData1, site_relicList, site_relicDetail, site_queryScore,  site_relicImg} from '@/api/site-home.js'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default{
    components:{
        mapMode,
        pieChart,
        lineChart,
        barChart,
        relicBox
    },
    data(){
        return{
            relicMsg:{},
            museumName:'',
            rooms:[],
            room:1,
            warningSeries:[],
            warningyAxis:[{name: '展柜风险趋势',type: 'value'}],
            TimeRang:['2023-10-10','2023-10-16'],
            TimeRang1:['2023-08-16 11:00:00','2023-08-18 11:00:00'],
            TimeRang2:['2023-08-01','2023-08-18'],
            WSDline:[
                {
                    name: '温度（℃）',
                    type: 'line',
                    smooth: true,
                    symbol: 'none',
                    areaStyle: {},
                    data: []
                },
                {
                    name: '湿度（%RH）',
                    yAxisIndex: 1,
                    type: 'line',
                    smooth: true,
                    symbol: 'none',
                    areaStyle: {},
                    data: []
                }
            ],
            WSDyAxis:[
                {name: '温度(℃)',type: 'value'},
                {name: '湿度(%RH)',alignTicks: true,type: 'value'}
            ],
            GZline:[{name: '光照（lx）',type: 'line',smooth: true,symbol: 'none',areaStyle: {},data:[]}],
            GZyAxis:[{name: '光照(lx)',type: 'value'}],
            ZWline:[{name: '紫外（ηW/cm²）', type: 'line', smooth: true, symbol: 'none', areaStyle: {}, data: []}],
            ZWyAxis:[{name: '紫外(ηW/cm²)',type: 'value'}],
            VOCline:[{name: 'VOC（ppb）',type: 'line',smooth: true,symbol: 'none',areaStyle: {},data:[]}],
            VOCyAxis:[{name: 'VOC(ppb)',type: 'value'}],
            senerType:'01',
            senerTypeList:[
                {name:'湿度', id:'01',unit:'%RH'},
                {name:'温度', id:'02',unit:'℃'},
                {name:'二氧化碳', id:'03',unit:'ppm'},
                {name:'光照', id:'04',unit:'lx'},
                {name:'紫外', id:'05',unit:'μw/cm2'}
            ],
            Times:[
                {label:'近24小时', value:1},
                {label:'近一周', value:2}
            ],
            limitTime:1,
            relicData:[],
            query:{
                page:1,
                size:5
            },
            total:1,
            dataline:[
                { 
                    name: '最大', type: 'line', smooth: true, symbol: 'none', data: [], 
                    // markLine: { 
                    //     silent: true, 
                    //     lineStyle: {  color: '#ff0000' },
                    //     data: [{ yAxis: 50 }, { yAxis: 100 }]
                    // }
                },
                {name: '平均值', type: 'line', smooth: true, symbol: 'none', data: []},
                {name: '最小值', type: 'line', smooth: true, symbol: 'none', data: []}
            ],
            datayAxis:[{name: '温度（℃）',type: 'value'}],
            relicFlag:false,
        }
    },
    computed:{
        ...mapGetters(['siteId'])
    },
    mounted(){
        
        this.getRoomList()
    },
    methods:{
        moment,
        getRoomList(){
            let minQuery = {
                'siteId':this.siteId,
                'envId':this.$route.query.floorsId,
                'isSelf':false,
                'isTree':true
            }
            site_envList(minQuery).then(res=>{
                this.rooms = res.data
                this.roomChange(this.$route.query.envId)
            })

            let minQuery2 = {
                'siteId':this.siteId,
                'envId':this.siteId,
                'isSelf':true,
                'isTree':true
            }
            site_envList(minQuery2).then(res=>{
                this.museumName = res.data[0].envName
            })
        },
        roomChange(val){
            this.room = val
            let minQuery = {
                'siteId':this.siteId,
                'envid':this.room
            }
            site_envMap(minQuery).then(res=>{
                let data = res.data.envs
                let src = `${window.g.imgPath}${res.data.layoutUrl}`
                let line = []
                data.forEach(el => {
                    let obj = {
                        type:'polygon',
                        points:JSON.parse(el.latlngs),
                        name:el.envName,
                        envId:el.envId 
                    }
                    line.push(obj)
                });
                this.$refs.mapMode.init(src, line)
            })
        },
        look(data){
            this.relicFlag = true
            site_relicDetail({'relicCode':data.relicCode}).then(r=>{
                this.relicMsg = r.data
                
            })
            site_relicImg(data.relicId).then(r=>{
                this.relicMsg['codeImg'] = r.data  
            })
             
        },
        backPage(){
            this.relicFlag = false
        }, 
        mapEvent(e){
            if(e.type == 'click'){
                this.CurrentZG = e.target.options.userData.envId
                this.Get_envData(1,'01',this.TimeRang1[0],this.TimeRang1[1]) //湿度
                this.Get_envData(2,'02',this.TimeRang1[0],this.TimeRang1[1]) //温度
                this.Get_envData(4,'04',this.TimeRang1[0],this.TimeRang1[1]) //光照
                this.Get_envData(5,'05',this.TimeRang1[0],this.TimeRang1[1]) //紫外
                this.Get_envData(6,'06',this.TimeRang1[0],this.TimeRang1[1]) //Voc
                this.Get_listDeviceData1(this.TimeRang2[0],this.TimeRang2[1]) //环境监测指标
                this.Get_RelicList(0)
                this.Get_site_queryScore()
            }
        },
        ChangeTimeRang(time){
            this.TimeRang = time
            this.Get_site_queryScore()
        },
        ChangeTimeRang1(time){
            this.TimeRang1 = time
            if(this.CurrentZG){
                this.Get_envData(1,'01',this.TimeRang1[0],this.TimeRang1[1]) //湿度
                this.Get_envData(2,'02',this.TimeRang1[0],this.TimeRang1[1]) //温度
                this.Get_envData(4,'04',this.TimeRang1[0],this.TimeRang1[1]) //光照
                this.Get_envData(5,'05',this.TimeRang1[0],this.TimeRang1[1]) //紫外
                this.Get_envData(6,'06',this.TimeRang1[0],this.TimeRang1[1]) //Voc
            }
            
        },
        ChangeTimeRang2(time){
            this.TimeRang2 = time
            if(this.CurrentZG){
                this.Get_listDeviceData1(this.TimeRang2[0],this.TimeRang2[1]) //环境监测指标
            }
        },
        Get_RelicList(type){
            if(type == 0){
                this.query.page = 1
            }
            let Query = {
                envId:this.CurrentZG,
                page:this.query.page,
                size:this.query.size
            }
            site_relicList(Query).then(r=>{
                this.relicData = r.data.relicUnitList
                this.total = r.data.total
            })
        },
        Get_site_queryScore(){
            let Query = {
                envid:this.CurrentZG,
                start:this.TimeRang[0],
                end:this.TimeRang[1]
            }
            site_queryScore(Query).then(r=>{
                this.warningSeries = [
                    {
                        name: '展柜风险趋势',
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        areaStyle: {},
                        data: r.data.map(i=>{
                            return [i.day, i.score]
                        })
                    }, 
                ]
            })
        },
        Get_envData(type,senerType,startDateTime, endDateTime){
            let Query = {
                siteId:this.siteId,
                envId:this.CurrentZG,
                envirParamType:senerType,
                startDateTime:startDateTime,
                endDateTime:endDateTime,
            }
            site_envData(Query).then(r=>{
                let data = r.data.data.map(i=>{
                    return [moment(i.collectTime).valueOf(), i.envirParamValue]
                })
                switch (type) {
                    case 1:
                        this.WSDline[1].data = data
                        break;
                    case 2:
                        this.WSDline[0].data = data
                        break;
                    case 4:
                        this.GZline[0].data = data
                        break;
                    case 5:
                        this.ZWline[0].data = data
                        break;
                    case 5:
                        this.VOCline[0].data = data
                        break; 
                    default:
                    //默认代码块
                }
            })
        },
        Get_listDeviceData1(start, end){
            let Query = {
                start:start,
                end:end,
                envid:this.CurrentZG,
                deviceType:this.senerType
            }
            site_listDeviceData1(Query).then(r=>{
                let Max = r.data.map(i=>{
                    return [moment(i.daytime).valueOf(), i.max]
                })
                let Min = r.data.map(i=>{
                    return [moment(i.daytime).valueOf(), i.min]
                })
                let Avg = r.data.map(i=>{
                    return [moment(i.daytime).valueOf(), i.avg]
                })
                this.dataline[0].data = Max 
                this.dataline[1].data = Min 
                this.dataline[2].data = Avg 
            })
        },        
        CurrentChange(page){
            this.query.page = page
            this.Get_RelicList(1)
        }
    }
}
</script>
<style lang="scss" scoped>
.maxBox{
    width:100%;
}

.pageBox{
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}
.titleMsgBox{
    width: 100%;
    height: calc(100% - 60px);
}
.titleCss{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.titleBox{
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #CFCFCF;
    box-sizing: border-box;
    padding:0px 20px;
    margin-bottom: 10px;
    img{
        width: 20px;
        height: 30px;
        object-fit: contain;
        vertical-align: middle;
    }
}
.topBox{
  width: 100%;
  height: 700px;
  display: flex;
  justify-content: space-between;
  
  .TB-left{
    width: 75%;
    height: 100%;
    background-color: white;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    .floorBox{
    }
    
  }
  .TB-right{
    width: calc(25% - 20px);
    height: 100%;
    background-color: white;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    .tips{
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:0px 20px;
        box-sizing: border-box;
    }
    .chartBox{
        width: 100%;
        height: calc(100% - 60px);
        margin-top: 10px;
        .chartItem{
            width: 100%;
            height: 50%;
            padding: 0px 10px;
            box-sizing: border-box;
        }
    }
  }
}

.centerBox{
    width: 100%;
    height: 500px;
    background-color: white;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    margin-top: 20px;
    .CB_main{
        display: flex;
        justify-content: space-between;
        .CB_item{
           width: calc(50% - 20px);
           height: 100%; 
           .CB_itemChart{
            width: 100%;
            height: 50%;
           }
           .CB_itemTop{
            display: flex;
            justify-content: space-between;
            .CB_itemTop_item{
                width: 50%;
                height: 100%;
            }
           }
        }
    }
}

.bottomBox{
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .BT-left{
        width: 60%;
        background-color: white;
        border-radius: 10px 10px 0px 0px;
        overflow: hidden;
        .operCss{
            display: flex;
            justify-content: center;
            align-items: center;
            .selectCss{
                margin-left: 20px;
            }
        }
    }
    .BT-right{
        width:calc(40% - 20px);
        background-color: white;
        border-radius: 10px 10px 0px 0px;
        overflow: hidden;
    }
}
.pagination{
    margin-top: 5px;
    display: flex;
    justify-content: center;
}
</style>